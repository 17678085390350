import React from 'react'
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { getIsPreviewStatus } from '../../state/app';
import { Image } from '../BlockLibrary/Common/Image';

const HeaderUC = ({ header, isPreview }) => {
     if (isPreview == true) {
          header = header.fields;
     }
     return (
          <div className="container cure-pet">
               <div className="logo-wrap">
                    <a href="/" >
                         <Image image={header.logo} />
                    </a>
               </div>
               <div className="logo-wrap">
                    <div className="float-right">
                         <Image image={header.logoCurePet} />
                    </div>
               </div>
          </div>
     )
}
function mapStateToProps(state) {
     return {
          isPreview: getIsPreviewStatus(state)
     };
}

HeaderUC.propTypes = {
     isPreview: PropTypes.bool,
}

const DefaultHeaderUC = connect(mapStateToProps)(HeaderUC);

export default DefaultHeaderUC;
