import React from 'react';
import { Image } from '../BlockLibrary/Common/Image';
import PropTypes from "prop-types";
import { getIsPreviewStatus } from '../../state/app';
import { connect } from "react-redux";

const HeaderPg = ({ header, isPreview }) => {
    if (isPreview == true) {
        header = header.fields;
    }
    return (<>
        <div className="container">
            <div className="top-header">
                <div className="logo">
                    <a href="/" >
                        <Image image={header.logo} />
                    </a>
                </div>
            </div>

        </div>
        <div className="header-pet-gazette">
            <div className="logo">
                {/* <a href="/"> */}
                <Image image={header.logoHz} />
                {/* </a> */}
            </div>
        </div>
    </>)
}

function mapStateToProps(state) {
    return {
        isPreview: getIsPreviewStatus(state)
    };
}

HeaderPg.propTypes = {
    isPreview: PropTypes.bool,
}

const DefaultHeaderPg = connect(mapStateToProps)(HeaderPg);


export default DefaultHeaderPg;
