import React from 'react';
import { ContentfulNavigationMenu } from '../BlockLibrary';
import { Image } from '../BlockLibrary/Common/Image';
import PropTypes from "prop-types";
import { getIsPreviewStatus } from '../../state/app';
import { connect } from "react-redux";
import Block from '../block';
import { SvgIcon } from '../BlockLibrary/Common/SvgIcon';

const HeaderMenuMobile = ({ header, isPreview, classTopMenu, specificPhone }) => {
     classTopMenu = 'top-menu';
     let phoneNumber = '';
     if (isPreview == true) {
          header = header.fields;

          if (typeof header != 'undefined' && header.topMenu != null) {
               //    if (header.topMenu.fields.dropdownsOrLinks.length == 1) {
               //         classTopMenu = 'top-menu one-item'
               //    }
          }
          phoneNumber = typeof specificPhone != 'undefined' ? specificPhone : header.phone;
     } else {
          if (typeof header != 'undefined' && header.topMenu != null) {
               //    if (header.topMenu.dropdownsOrLinks.length == 1) {
               //         classTopMenu = 'top-menu one-item'
               //    }
          }
          phoneNumber = specificPhone != null ? specificPhone : header.phone;
     }

     return (
          <div className="container">
               <div className="is-desktop header-layout-menumobile">
                    <div className="top-header">
                         {header.logo && (
                              <div className="logo">
                                   <a href="/" >
                                        <Image image={header.logo} />
                                   </a>
                              </div>
                         )}
                    </div>
               </div>
               <div className="is-mobile header-mobile header-layout-menumobile-mobile">
                    <div className="top-header">
                         <div className="logo">
                              <a href="/">
                                   <Image image={header.logo} />
                              </a>
                         </div>
                         {
                              phoneNumber && (
                                   <div className="phone-number-navigation">
                                        <a href={`tel:${phoneNumber}`}>
                                             {/* <img src="/images/icons/phone-icon-gray.svg" alt="Phone Number" /> */}
                                             <SvgIcon type="phone" />
                                        </a>
                                   </div>
                              )
                         }
                    </div>
                    <nav className="menu-mobile">
                         {header.menuMobile && (
                              <ul className="menu-mobile-list">
                                   <ContentfulNavigationMenu menuItem={header.menuMobile} />
                                   {/* <li><Block block={isPreview ? header.menuMobile.fields.dropdownsOrLinks : header.menuMobile.dropdownsOrLinks} /></li> */}
                              </ul>
                         )}
                    </nav>
               </div>
          </div>
     )
}

function mapStateToProps(state) {
     return {
          isPreview: getIsPreviewStatus(state)
     };
}

HeaderMenuMobile.propTypes = {
     isPreview: PropTypes.bool,
     classTopMenu: PropTypes.string
}

const DefaultHeaderMenuMobile = connect(mapStateToProps)(HeaderMenuMobile);


export default DefaultHeaderMenuMobile;
