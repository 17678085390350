import React from 'react';
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { getIsPreviewStatus } from '../../state/app';
import { changeTrackingCode } from '../BlockLibrary/Common/changeTrackingCode';
import { getTrackingCode, getTrackingPhone } from '../../state/app';

function mapStateToProps(state) {
     return {
          isPreview: getIsPreviewStatus(state),
          trackingCode: getTrackingCode(state),
          trackingPhone: getTrackingPhone(state),
     };
}

const handlePILink = (Text) => {

     const domain = process.env.PI_DOMAIN.slice(0, -1);
     return Text.startsWith('/')?domain+Text:Text;
}

const NavigationLink = (link) => {
     var url = null;
     var target = link.urlTarget == 'Same Tab' ? '_self' : '_blank';
     if (link.mediaFile == null) {
          if (link.urlToUse == 'External URL') {
               url = (<a href={changeTrackingCode(link.externalUrl, link.trackingCode, link.trackingPhone)} target='_blank' target={target} >{link.linkText}</a>);
          } else {
               if (link.url) {
                    var linkUrl = (link.url.includes('#') || link.url.charAt(link.url.length - 1) == "/") ? handlePILink(link.url) : (handlePILink(link.url) + '/');
                    url = <a href={linkUrl} target={target}>{link.linkText}</a>
               }
          }
     } else {
          url = (<a href={handlePILink(link.mediaFile.file.url)} target={target}>{link.linkText}</a>);
     }
     return (
          <>{url}</>
     );
};

NavigationLink.propTypes = {
     isPreview: PropTypes.bool,
}

const ContentfulNavigationLink = connect(mapStateToProps)(NavigationLink);

export { ContentfulNavigationLink }
