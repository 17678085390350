import React from 'react';
import { ContentfulNavigationMenu } from '../BlockLibrary';
import { Image } from '../BlockLibrary/Common/Image';
import PropTypes from "prop-types";
import { getIsPreviewStatus } from '../../state/app';
import { connect } from "react-redux";
import Block from '../block';
import { SvgIcon } from '../BlockLibrary/Common/SvgIcon';

const Header = ({ header, isPreview, classTopMenu, specificPhone }) => {
     classTopMenu = 'top-menu';

     let phoneNumber = '';
     if (isPreview == true) {
          header = header.fields;

          if (typeof header != 'undefined' && header.topMenu != null) {
               if (header.topMenu.fields.dropdownsOrLinks.length == 1) {
                    classTopMenu = 'top-menu one-item'
               }
          }
          phoneNumber = typeof specificPhone != 'undefined' ? specificPhone : header.phone;
     } else {
          if (typeof header != 'undefined' && header.topMenu != null) {
               if (header.topMenu.dropdownsOrLinks.length == 1) {
                    classTopMenu = 'top-menu one-item'
               }
          }
          phoneNumber = specificPhone != null ? specificPhone : header.phone;
     }

     return (
          <div className="container">
               <div className="is-desktop">
                    <div className="top-header">
                         {header.logo && (
                              <div className="logo">
                                   <a href="/" >
                                        <Image image={header.logo} />
                                   </a>
                              </div>
                         )}
                         <nav className={classTopMenu}>
                              <ul className="list-inline" id="list-inline-desktop">
                                   {header.topMenu && <ContentfulNavigationMenu menuItem={header.topMenu} classItem={`list-inline-item`} />}
                              </ul>
                         </nav>
                         {
                              phoneNumber && (
                                   <div className="phone-number">
                                        <a href={`tel:${phoneNumber}`} className="is-desktop">{phoneNumber}</a>
                                   </div>
                              )
                         }
                         {
                              header.ctaButton &&
                              <div className="cta-button-header"><Block block={header.ctaButton} /></div>
                         }
                    </div>
                    {header.mainMenu && <nav className="bottom-header">
                         <ul className="main-menu" id="main-menu-desktop">
                              <ContentfulNavigationMenu menuItem={header.mainMenu} />
                         </ul>
                    </nav>}
               </div>
               <div className="is-mobile header-mobile">
                    <div className="top-header">
                         {header.mainMenu &&
                              <button className="menu-toggle-button" aria-label="Menu toggle">
                                   <span className="icon-bar bar1"></span>
                                   <span className="icon-bar bar2"></span>
                                   <span className="icon-bar bar3"></span>
                              </button>
                         }
                         <div className="logo">
                              <a aria-label="Link to Home" href="/">
                                   <Image image={header.logo} />
                              </a>
                         </div>
                         {
                              phoneNumber && (
                                   <div className="phone-number-navigation">
                                        <a href={`tel:${phoneNumber}`}>
                                             <SvgIcon type="phone" />
                                        </a>
                                   </div>
                              )
                         }
                    </div>
                    <nav className="bottom-header">
                         {header.mainMenu && (
                              <ul className="main-menu-mobile" id="main-menu-mobile">
                                   <ContentfulNavigationMenu menuItem={header.mainMenu} />
                                   <li><Block block={isPreview ? header.topMenu.fields.dropdownsOrLinks[4] : header.topMenu.dropdownsOrLinks[4]} /></li>
                              </ul>
                         )}

                         {
                              phoneNumber && (
                                   <div className="phone-number">
                                        <a href={`tel:${phoneNumber}`}>
                                             <SvgIcon type="phone" />
                                             {phoneNumber}
                                        </a>
                                   </div>
                              )
                         }
                    </nav>
               </div>
          </div>
     )
}

function mapStateToProps(state) {
     return {
          isPreview: getIsPreviewStatus(state)
     };
}

Header.propTypes = {
     isPreview: PropTypes.bool,
     classTopMenu: PropTypes.string
}

const DefaultHeader = connect(mapStateToProps)(Header);


export default DefaultHeader;
