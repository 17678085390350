import React from 'react'
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { getIsPreviewStatus } from '../../state/app';
import { ContentfulNavigationMenu } from '../BlockLibrary';

import { Image } from '../BlockLibrary/Common/Image';

const DefaultHeaderWy = ({ header, isPreview }) => {
    if (isPreview == true) {
        header = header.fields;
    }
    return (
        <div className="container">
            <div className="top-header top-header-wacky">
                <div className="logo">
                    <a href="/" >
                        <Image image={header.logo} />
                    </a>
                </div>

                <button className="menu-toggle-button" aria-label="Menu toggle">
                    <span className="icon-bar bar1"></span>
                    <span className="icon-bar bar2"></span>
                    <span className="icon-bar bar3"></span>
                </button>

                <div className="menu-dropdown">
                    <ul>
                        {header.topMenu && <ContentfulNavigationMenu layout={header.layout} menuItem={header.topMenu} classItem={`list-inline-item`} />}
                    </ul>
                </div>
            </div>
        </div>
    )
}
function mapStateToProps(state) {
    return {
        isPreview: getIsPreviewStatus(state)
    };
}

DefaultHeaderWy.propTypes = {
    isPreview: PropTypes.bool,
}

const DefaultHeaderWacky = connect(mapStateToProps)(DefaultHeaderWy);

export default DefaultHeaderWacky;
