import React from 'react'
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { getIsPreviewStatus } from '../../state/app';
import { Image } from '../BlockLibrary/Common/Image';

const DefaultHeaderCPC = ({ header, isPreview }) => {
    if (isPreview == true) {
        header = header.fields;
    }
    return (
        <div className="container cure-pet">
            <div className="logo-wrap">
                <a href="http://www.curepetcancer.com" >
                    <Image image={header.logoCurePet} />
                </a>
            </div>
            <div className="logo-wrap">
                <a href="/" className="float-right">
                    <Image image={header.logo} />
                </a>
            </div>
        </div>
    )
}
function mapStateToProps(state) {
    return {
        isPreview: getIsPreviewStatus(state)
    };
}

DefaultHeaderCPC.propTypes = {
    isPreview: PropTypes.bool,
}

const DefaultHeaderCurePet = connect(mapStateToProps)(DefaultHeaderCPC);

export default DefaultHeaderCurePet;
